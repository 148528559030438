import 'bootstrap-select';

import Select2Element from './form/select2.js';
import CheckboxElement from './form/checkbox.js';
import DateRangePickerElement from './form/daterangepicker.js';
import CleaveElement from './form/cleave.js';

export default class Form {

    constructor(form) {
        this.formElement = form;

        this.inputs = this.formElement ? this.formElement.querySelectorAll('input') : [];
        this.textareas = this.formElement ? this.formElement.querySelectorAll('textarea') : [];
        this.checkboxes = this.formElement ? this.formElement.querySelectorAll('input[type=checkbox]') : [];
        this.selectpickers = this.formElement ? this.formElement.querySelectorAll('select.selectpicker') : [];
        this.select2 = this.formElement ? this.formElement.querySelectorAll('select.select2') : [];
        this.datepickers = this.formElement ? this.formElement.querySelectorAll('input[data-provide="datepicker"]') : [];
        this.daterangepickers = this.formElement ? this.formElement.querySelectorAll('input[data-provide="daterangepicker"]') : [];
        this.cleaveinputs = this.formElement ? this.formElement.querySelectorAll('input[data-provide="yearmonthpicker"]') : [];

        this.elements = [
            ...this.inputs,
            ...this.textareas,
            ...this.checkboxes,
            ...this.selectpickers,
            ...this.datepickers,
            ...this.daterangepickers,
            ...this.cleaveinputs
        ];

        this.setLocale();
        this.setSubmitButton();

        this.initCheckboxes();
        this.initDatePickers();
        this.initDateRangePickers();
        this.initSelectPickers();
        this.initSelect2();
        this.initCleaveInputs();
    }

    setLocale = () => {
        if (langObject) this.locale = langObject
    }

    setSubmitButton = (submitButton = null) => {
        const { formElement } = this;

        if (submitButton) {
            this.submitButton = submitButton;
        } else {
            const submitButtonInsideForm = formElement.querySelector(`button[type='submit']`);
            const submitButtonOutsideForm = document.querySelector(`button[form='${formElement.id}'][type='submit']`);

            this.submitButton = (submitButtonInsideForm ?? submitButtonOutsideForm);
        }
    }

    initCheckboxes = () => {
        this.checkboxes.forEach(input => {
            new CheckboxElement(input);
        });
    }
    initSelectPickers = () => {
        $.fn.selectpicker.Constructor.DEFAULTS.liveSearch = true;
        $.fn.selectpicker.Constructor.DEFAULTS.size = 8;

        this.selectpickers.forEach(input => {
            $(input).selectpicker();
        });
    }
    initSelect2 = () => {
        this.select2.forEach(input => {
            new Select2Element(input);
        });
    }
    initDatePickers = () => {
        this.datepickers.forEach(input => {
            new DateRangePickerElement(input);
        });
    }
    initDateRangePickers = () => {
        this.daterangepickers.forEach(input => {
            new DateRangePickerElement(input);
        });
    }
    initCleaveInputs = () => {
        this.cleaveinputs.forEach(input => {
            new CleaveElement(input);
        });
    }

    clearData = () => {
        const inputs = this.formElement.querySelectorAll('input');
        inputs.forEach(input => {
            input.value = "";
        });

        const textareas = this.formElement.querySelectorAll('textarea');
        textareas.forEach(textarea => {
            textarea.value = "";
        });

        const checkboxes = this.formElement.querySelectorAll('input[type=checkbox]');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });

        const selects = this.formElement.querySelectorAll('select');
        selects.forEach(select => {
            select.selectedIndex = 0;
            $(select).selectpicker('refresh');
        });
    }

    disableInputs = () => {
        this.elements.forEach(element => {
            element.disabled = true;
        });

        this.selectpickers.forEach(element => {
            $(element).selectpicker('refresh');
        });

        this.select2.forEach(element => {
            element.disabled = true;
            $(element).select2();
        });

        return this;
    }

    disableSubmitButton = () => {
        const { submitButton } = this;
        submitButton.disabled = true;
    }

    enableSubmitButton = () => {
        const { submitButton } = this;
        submitButton.disabled = false;
    }
}