import 'moment'
import 'daterangepicker';

export default class DateRangePickerElement {

    constructor(input) {

        this.setLocale();
        
        this.input = input;
        this.inputType = input.getAttribute('data-provide');
        
        switch (this.inputType) {
            case 'datepicker':
                this.initDatePicker();
              break;
            case 'daterangepicker':
                this.initDateRangePicker();
              break;
          }
    }
    
    setLocale = () => {
        if (langObject) this.locale = langObject
    }

    initDatePicker = () => {
        const { locale, input } = this;
        
        let timePicker = $(input).data('time') === true;
        let timeFormat = (timePicker ? locale.labelDatetimeFormat : locale.labelDateFormat );

        $(input).daterangepicker({
            singleDatePicker: true,
            autoUpdateInput: false,
            autoApply: false,
            timePicker: timePicker,
            timePicker24Hour: timePicker,
            drops: "auto",
            locale: {
                format: timeFormat,
                separator: " - ",
                applyLabel: locale.labelApply,
                cancelLabel: locale.labelCancel,
                fromLabel: locale.labelFrom,
                toLabel: locale.labelTo,
                customRangeLabel: "Custom",
                weekLabel: "W",
                daysOfWeek: locale.calendarDayNames,
                monthNames: locale.calendarMonthNames,
                firstDay: 1
            },
            buttonClasses: 'btn',
            applyButtonClasses: 'btn-primary',
            cancelClass: 'btn-outline-secondary',
            cancelLabel: locale.labelClear
        });

        /**
         * Daterangepicker zbudowany z dwóch datepickerów wymaga możliwości zerowania wartości date_end.
         */
        if( $(input).attr('name') === 'date_end' || $(input).attr('data-name') === 'date_end' ) {
            $(input).on('cancel.daterangepicker', function(ev, picker) {
                $(this).val('');
            });
        }

        $(input).on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format(timeFormat));
        });
    }
    initDateRangePicker = () => {
        const { locale, input } = this;

        let timePicker = $(input).data('time') === 'true';
        let timeFormat = (timePicker ? locale.labelDatetimeFormat : locale.labelDateFormat );

        $(input).daterangepicker({
            autoUpdateInput: false,
            autoApply: false,
            timePicker: timePicker,
            timePicker24Hour: timePicker,
            locale: {
                format: timeFormat,
                separator: " - ",
                applyLabel: locale.labelApply,
                cancelLabel: locale.labelCancel,
                fromLabel: locale.labelFrom,
                toLabel: locale.labelTo,
                customRangeLabel: "Custom",
                weekLabel: "W",
                daysOfWeek: locale.calendarDayNames,
                monthNames: locale.calendarMonthNames,
                firstDay: 1
            },
            buttonClasses: 'btn',
            applyButtonClasses: 'btn-primary',
            cancelClass: 'btn-outline-secondary',
            cancelLabel: locale.labelClear
        });
        
        $(input).on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
        });
        $(input).on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format(timeFormat) + ' - ' + picker.endDate.format(timeFormat));
        });
    }
}