require('select2');
require('./../../../../../node_modules/select2/dist/js/i18n/en.js');
require('./../../../../../node_modules/select2/dist/js/i18n/pl.js');

export default class Select2Element {

    constructor(input, ajax = null) {

        this.setLocale();

        this.input = input;

        this.init(ajax);
    }

    setLocale = () => {
        if (langObject) this.locale = langObject;
        if (language) this.language = language;
    }

    init = (ajax) => {
        const { locale, language, input } = this;
        const maximumSelectionLength = ($(input).data('maximum-selection-length') ? $(input).data('maximum-selection-length') : 0);

        let select2 = $(input).select2({
            language: 'pl',
            placeholder: $(input).attr('placeholder'),
            tags: $(input).data('tags') === true,
            tokenSeparators: [',', ' '],
            dropdownParent: $(document).find(`#wrapper`),
            maximumSelectionLength: maximumSelectionLength,
            triggerChange: true,
            ajax
        });
    }
}