import Modal from './modal';
import html2canvas from "html2canvas";

export default class BugReport {

    constructor(global) {
        this.globalFunctions = global;
        this.locale = global.locale;

        if (document.getElementById('bugReportModal') !== null) {
            this.modal = new Modal("bugReportModal");
            this.createOpenButton();
            this.bindSubmitModalButton();
        }
    }

    createOpenButton = () => {
        const button = document.createElement('button');
        button.type = 'button';
        button.classList.add('btn', 'btn-danger', 'bug-report-button');
        button.dataset.toggle = 'modal';
        button.dataset.target = "#bugReportModal";

        const p1 = document.createElement('p');
        p1.innerHTML = this.locale.globalButtonBugReport;
        button.appendChild(p1);

        const p2 = document.createElement('p');
        p2.innerHTML = '<i class="fa-solid fa-bug"></i>';
        button.appendChild(p2);

        document.body.append(button);
    }

    bindSubmitModalButton = () => {
        const { formElement, submitButton } = this.modal;

        submitButton.addEventListener('click', async () => {
            this.modal.closeModal();
            await this.sleep(200);
            const screen = await this.makeScreenShot();

            const formData = new FormData(formElement);
            formData.append('view', window.location.pathname);
            formData.append('image', screen);

            const response = await fetch(formElement.action, {
                method: "POST",
                body: formData,
            });

            const notification = await response.json();
            this.globalFunctions.formatFormResponse(notification);
        });
    }

    makeScreenShot = async () => {
        const canvas = await html2canvas(document.body);
        return canvas.toDataURL('image/png');
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}