export default class CheckboxElement {

    constructor(input) {

        this.setLocale();
        
        this.input = input;
        
        this.divPretty = input.parentNode; // div.pretty
        this.inputGroup =  this.divPretty.parentNode; // div.input-group

        this.color = input.getAttribute('data-color');
        this.label = this.inputGroup.querySelector('small');

        this.inputType =  this.divPretty.classList.contains('p-toggle') ? 'toggle' : 'checkbox'; // div.input-group

        switch (this.inputType) {
            case 'toggle':
                this.initToggle();
              break;
            case 'checkbox':
                this.initCheckbox();
              break;
        }
    }
    
    setLocale = () => {
        if (langObject) this.locale = langObject
    }

    initToggle = () => {
        const { locale, input, label, color} = this;
    }
    initCheckbox = () => {
        const { locale, input, label, color} = this;
        
        input.addEventListener('change',function(){
            if( input.checked ) {
                label.classList.add('text-'+color);
                label.classList.remove('text-muted');
            } else {
                label.classList.add('text-muted');
                label.classList.remove('text-'+color);
            }
        });
    }
}