import 'bootstrap-select';
import 'moment'
import 'daterangepicker';
import Form from './form.js';


export default class Modal {

    constructor(modalId) {
        this.modal = document.getElementById(modalId);
        this.submitButton = this.modal.querySelector(`.modal-footer button:last-child`); // FIXME: Czy to nie powinno być `button[type='submit']`?
        this.formElement = this.modal.querySelector(`form`);

        if (this.formElement) {
            this.setForm(new Form(this.formElement));
        }
    }

    setForm = form => {
        this.form = form;
    }

    openModal = () => {
        $(this.modal).modal(`show`);
    }

    closeModal = () => {
        $(this.modal).modal(`hide`);
    }

    clearData = () => {
        const { form } = this;
        form.clearData();
    }

    turnOffModalLoader = () => {
        // modal.querySelector('.modal-body').style.display = "block";
        // modal.querySelector('.modal-footer').style.display = "flex";
        // modal.querySelector('.modal-loader').classList.add('off');
    }

    turnOnModalLoader = () => {
        // modal.querySelector('.modal-body').style.display = "none";
        // modal.querySelector('.modal-footer').style.display = "none";
        // modal.querySelector('.modal-loader').classList.remove('off');

        // $(modal).on('hide.bs.modal', function (e) {
        //     e.preventDefault();
        //     e.stopPropagation();
        //     return false;
        // });
    }
}