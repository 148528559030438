import BugReport from "./BugReport";

require('regenerator-runtime');

const jQuery = $ = require('jquery');
require('jquery.easing');

require('popper.js');
require('bootstrap');
require('bootstrap-notify'); // consider https://izitoast.marcelodolza.com/

import Menu from './menu.js';

export default class Global {

    notifyIcon = {
        danger: 'fas fa-exclamation-circle',
        warning: 'fas fa-exclamation-circle',
        success: 'fas fa-check-circle',
        primary: 'fas fa-circle-info',
    }

    notifyType = {
        danger: 'danger',
        warning: 'warning',
        success: 'success',
        primary: 'primary',
    }

    baseUrl = null;

    constructor() {
        new Menu();

        this.baseUrl = typeof referenceLists === 'undefined' ? null : referenceLists.baseUrl;
        this.setLocale();
        this.notifyDefaults();
        this.sessionNotify();
        this.languageSelectorInit();

        this.bugReport = new BugReport(this);
    }

    languageSelectorInit = () => {
        const button = document.querySelector('.langs-select__selected');

        if (button !== null) {
            const listContainer = document.querySelector('.langs-select__list');

            button.addEventListener('click', () => {
                listContainer.classList.toggle('hidden');
            });

            const items = document.querySelectorAll('.langs-select__item');
            items.forEach(item => {
                item.addEventListener('click', async () => {
                    const url = `./../../UserManagement/changeLanguageNonUser/${item.dataset.lang}`;
                    const response = await fetch(url, {
                        method: "GET",
                    });

                    const notification = await response.json();
                    this.formatFormResponse(notification);
                })
            });
        }
    }

    setLocale = () => {
        if (langObject) {
            this.locale = langObject;
        }
    }

    notifyDefaults = () => {
        $.notifyDefaults({
            z_index: 1600,
            allow_dismiss: true,
            animate: {
                enter: 'animated slideInRight',
                exit: 'animated slideOutRight'
            },
            placement: {
                from: "top",
                align: "right"
            },
        });
    }

    sessionNotify = () => {
        if (notifySession) {
            this.makeNotify(this.notifyType.success, notifySession);
        }
    }

    getNotifySettings = type => {
        switch (type) {
            case this.notifyType.danger:
                return {
                    type: this.notifyType.danger,
                    icon: this.notifyIcon.danger
                }
            case this.notifyType.success:
                return {
                    type: this.notifyType.success,
                    icon: this.notifyIcon.success
                }
            case this.notifyType.warning:
                return {
                    type: this.notifyType.warning,
                    icon: this.notifyIcon.warning
                }
            default:
                throw new Error(`Niewłaściwy typ powiadomienia: ${type}`);
        }
    }

    makeNotify = (type, message) => {
        const settings = this.getNotifySettings(type);

        $.notify({
            icon: settings.icon,
            message: message
        }, {
            type: settings.type
        });
    }

    makeDefaultErrorNotify = (error = null) => {
        if (error !== null) {
            console.error(error);
        }

        this.makeNotify(this.notifyType.danger, "Wystąpił nieoczekiwany problem z działaniem aplikacji.");
    }

    formatFormResponse = response => {
        if (response.url !== null) {
            window.location.href = response.url;
        }

        if (response.errors.length > 0) {
            response.errors.forEach(error => {
                this.makeNotify(this.notifyType.danger, error);
            });
        }

        if (response.success !== null) {
            this.makeNotify(this.notifyType.success, response.success);
        }
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    lpad = (num, places, str = 0) => String(num).padStart(places, str);

    scrollToElement = (element) => {
        element.scrollIntoView(false);
    }
}