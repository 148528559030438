import 'cleave.js';

export default class CleaveElement {

    constructor(input) {
        this.setLocale();

        this.input = input;
        this.inputType = input.getAttribute('data-provide');

        switch (this.inputType) {
            case 'yearmonthpicker':
                this.initMonthYearPicker();
                break;
        }
    }

    setLocale = () => {
        if (langObject) this.locale = langObject
    }

    initMonthYearPicker = () => {
        const { locale, input } = this;
        let element = new Cleave(input, {
            date: true,
            datePattern: ['Y', 'm']
        });
    }
}